import axios, { AxiosInstance } from "axios";
import binanceURL from "./binanceURL";
import {
    UserRegister,
    TicketRequest,
    userInfo,
    userUpdate,
    UserChangePasswordWithTicket,
    UserDelete,
    ReferralCodeExist,
    UserToken,
    TokenChart,
    UserLog,
    PurchaseCoinDeposit,
    PurchaseCoinList,
    PurchaseCoinUpload,
    PurchaseCoinSign,
    PurchaseCoinCancel,
    UserWithdraw,
    NewNoticeList,
    PushList,
    UserPushUpdate,
} from "./interface";
import { iRes } from "./commonResponse";

export class ResData extends iRes {
    [x: string]: any;
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    transformRequest: [
        (data) => {
            return JSON.stringify(data);
        },
    ],
    transformResponse: [
        (data) => {
            return JSON.parse(data);
        },
    ],
    headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Pragma: "no-cache",
    },
});

const axiosCommonResponse = (url: string, data?: any) => {
    return new Promise<ResData>((resolve, reject) => {
        axiosInstance
            .post(url, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const axiosMethods = {
    // 회원 가입
    userRegister(param: UserRegister): Promise<any> {
        return axiosCommonResponse("/v2/user/register", param);
    },

    // 티켓 요청
    ticketRequest(param: TicketRequest): Promise<any> {
        return axiosCommonResponse("/v2/ticket/request", param);
    },

    // 유저 정보 조회
    userInfo(param: userInfo): Promise<any> {
        return axiosInstance.get("/v1/user/info", {
            params: param
        });
    },

    // 추가 정보 변경
    userUpdate(param: userUpdate): Promise<any> {
        return axiosInstance.get("/v1/user/update", {
            params: param
        });
    },

    // 비밀번호 변경
    userChangePasswordWithTicket(param: UserChangePasswordWithTicket): Promise<any> {
        return axiosInstance.get("/v1/user/changePasswordWithTicket", {
            params: param
        });
    },

    // 회원 탈퇴
    userDelete(param: UserDelete): Promise<any> {
        return axiosInstance.get("/v1/user/delete", {
            params: param
        });
    },

    // 추천인조회
    referralCodeExist(param: ReferralCodeExist): Promise<any> {
        return axiosInstance.get("/v1/referralCode/exist", {
            params: param
        });
    },

    // 유저 토큰 조회
    userToken(param: UserToken): Promise<any> {
        return axiosInstance.get("/v1/user/token", {
            params: param
        });
    },

    // 차트 정보 조회
    tokenChart(param: TokenChart): Promise<any> {
        return axiosInstance.get("/v1/token/chart", {
            params: param
        });
    },

    // 토큰별 정보 조회
    tokenInfo(): Promise<any> {
        return axiosInstance.get("/v1/token/info");
    },
    
    // 유저 로그 조회
    userLog(param: UserLog): Promise<any> {
        return axiosInstance.get("/v1/user/log", {
            params: param
        });
    },
    
    // 상품 입금 요청, 중복 입금요청 제한
    purchaseCoinDeposit(param: PurchaseCoinDeposit): Promise<any> {
        return axiosInstance.get("/v1/purchaseCoin/deposit", {
            params: param
        });
    },
    
    // 상품 입금 요청, 중복 입금요청 제한
    purchaseCoinList(param: PurchaseCoinList): Promise<any> {
        return axiosInstance.get("/v1/purchaseCoin/list", {
            params: param
        });
    },
    
    // 사인 업로드
    purchaseCoinUpload(param: PurchaseCoinUpload): Promise<any> {
        const formData = new FormData();
        formData.append("file", param.file);

        return axiosInstance.post("/v1/purchaseCoin/upload/" + param.idx + '/' + param.id + '/' + param.ticketId, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            transformRequest: [
                () => {
                    return formData;
                },
            ],
        });
    },

    // 상품 입금 요청, 중복 입금요청 제한
    purchaseCoinSign(param: PurchaseCoinSign): Promise<any> {
        return axiosInstance.get("/v1/purchaseCoin/sign", {
            params: param
        });
    },

    // 상품 해지 요청 (state 1, 3 일때 호출가능)
    purchaseCoinCancel(param: PurchaseCoinCancel): Promise<any> {
        return axiosInstance.get("/v1/purchaseCoin/cancel", {
            params: param
        });
    },

    // 출금 요청
    userWithdraw(param: UserWithdraw): Promise<any> {
        return axiosInstance.get("/v1/user/withdraw", {
            params: param
        });
    },

    // 공지사항
    newNoticeList(param: NewNoticeList): Promise<any> {
        return axiosInstance.get("/v1/newNotice/list", {
            params: param
        });
    },

    // 알림
    pushList(param: PushList): Promise<any> {
        return axiosInstance.get("/v1/push/list", {
            params: param
        });
    },

    // 알림 업데이트
    userPushUpdate(param: UserPushUpdate): Promise<any> {
        return axiosInstance.get("/v1/user/push/update", {
            params: param
        });
    },
};

const axiosInstance_Binance: AxiosInstance = axios.create({
    baseURL: '', // process.env.VUE_APP_BINANCE_API_URL,
    transformRequest: [
        (data) => {
            return JSON.stringify(data);
        },
    ],
    transformResponse: [
        (data) => {
            return JSON.parse(data);
        },
    ],
    headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Pragma: "no-cache",
    },
});

const axiosMethods_Binance = {
    // 바이낸스
    binance(): Promise<any> {
    const symbols = '["BTCUSDT","ETHUSDT","BNBUSDT","ADAUSDT","XRPUSDT","TRXUSDT","USDCUSDT","SOLUSDT"]'

    return binanceURL.get(`/api/v3/ticker/24hr?symbols=${encodeURIComponent(symbols)}`);
    //return axiosInstance_Binance.get(`/api/v3/ticker/24hr?symbols=${encodeURIComponent(symbols)}`);
    },
}

export default { axiosInstance, axiosMethods, axiosInstance_Binance, axiosMethods_Binance };
