import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Index from '../views/Index.vue'
import Trade from '../views/Trade.vue'
import Wallet from '../views/Wallet.vue'
import My from '../views/My.vue'
import Menu from '../views/Menu.vue'

// member
import Login from '../views/Member/Login.vue'
import Terms from '../views/Member/Terms.vue'
import CreateId from '../views/Member/CreateId.vue'
import Recommender from '../views/Member/Recommender.vue'
import Info from '../views/Member/Info.vue'
import Pin from '../views/Member/Pin.vue'
import ChangeInfo from '../views/Member/ChangeInfo.vue'
import ChangePw from '../views/Member/ChangePw.vue'
import Secession from '../views/Member/Secession.vue'

// setting
import Language from '../views/Setting/Language.vue'

// terms
import SelectTerms from '../views/Terms/SelectTerms.vue'
import Service from '../views/Terms/Service.vue'
import Privacy from '../views/Terms/Privacy.vue'

// menu
import Notice from '../views/Menu/Notice.vue'
import Alarm from '../views/Menu/Alarm.vue'

// contract
import Sell from '../views/Contract/Sell.vue'
import Contract from '../views/Contract/Contract.vue'
import Signing from '../views/Contract/Signing.vue'

// wallet
import Detail from '../views/Wallet/Detail.vue'
import Withdraw from '../views/Wallet/Withdraw.vue'

const routes: Array<RouteRecordRaw> = [
  { path: "/", name: "Index", component: Index }, // 메인 페이지
  { path: "/Trade", name: "Trade", component: Trade }, // 트레이드 페이지
  { path: "/Wallet", name: "Wallet", component: Wallet }, // 월렛 페이지
  { path: "/My", name: "My", component: My }, // 마이 페이지
  { path: "/Menu", name: "Menu", component: Menu }, // 메뉴 페이지

  // member
  { path: "/Login", name: "Login", component: Login }, // 로그인 페이지
  { path: "/Terms", name: "Terms", component: Terms }, // 가입 페이지1
  { path: "/CreateId", name: "CreateId", component: CreateId }, // 가입 페이지2
  { path: "/Recommender", name: "Recommender", component: Recommender }, // 가입 페이지3
  { path: "/Info", name: "Info", component: Info }, // 가입 페이지3
  { path: "/Pin", name: "Pin", component: Pin }, // 핀번호 페이지
  { path: "/ChangeInfo", name: "ChangeInfo", component: ChangeInfo }, // 정보 변경 페이지
  { path: "/ChangePw", name: "ChangePw", component: ChangePw }, // 비밀번호 변경 페이지
  { path: "/Secession", name: "Secession", component: Secession }, // 탈퇴 페이지

  // setting
  { path: "/Language", name: "Language", component: Language }, // 언어 페이지

  // terms
  { path: "/SelectTerms", name: "SelectTerms", component: SelectTerms }, // 약관 및 정책 페이지
  { path: "/Service", name: "Service", component: Service }, // 서비스 이용약관 페이지
  { path: "/Privacy", name: "Privacy", component: Privacy }, // 개인정보 이용약관 페이지

  // menu
  { path: "/Notice", name: "Notice", component: Notice }, // 공지사항 페이지
  { path: "/Alarm", name: "Alarm", component: Alarm }, // 공지사항 페이지

  // contract
  { path: "/Sell", name: "Sell", component: Sell }, // 판매 페이지
  { path: "/Contract", name: "Contract", component: Contract }, // 계약서 페이지
  { path: "/Signing", name: "Signing", component: Signing }, // 서명 페이지

  // wallet
  { path: "/Detail", name: "Detail", component: Detail }, // 지갑 상세 페이지
  { path: "/Withdraw", name: "Withdraw", component: Withdraw }, // 출금 페이지
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
