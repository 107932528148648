import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { useStore } from "@/pinia";
import { i18n } from "./i18n";
import axios from "./axios";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PopupJoin from "@/components/PopupJoin.vue";
import PopupOneBtn from "@/components/PopupOneBtn.vue";
import PopupTwoBtn from "@/components/PopupTwoBtn.vue";
import PopupNotice from "@/components/PopupNotice.vue";
import PopupTermination from "@/components/PopupTermination.vue";

import "@/assets/css/reset.css";
import "@/assets/css/index.css";
import "@/assets/css/component.css";

import { VueClipboard } from "@soerenmartius/vue3-clipboard";
import Vue3Signature from "vue3-signature";

import "swiper/css";

const app = createApp(App);

const pinia = createPinia();
app.use(pinia);
app.use(router);
const useI18n = i18n();
app.use(useI18n);
app.use(VueClipboard);

app.component("Header", Header);
app.component("Footer", Footer);
app.component("PopupJoin", PopupJoin);
app.component("PopupOneBtn", PopupOneBtn);
app.component("PopupTwoBtn", PopupTwoBtn);
app.component("PopupNotice", PopupNotice);
app.component("PopupTermination", PopupTermination);
app.component("Vue3Signature", Vue3Signature);

app.mount("#app");