export interface LogNamesData {
  [key: string]: {
    name: string;
    color: string;
  };
}

// 입금
export const Deposit = 1;
// 출금요청
export const WithdrawReq = 2;
// 출금완료
export const Withdrawed = 3;
// 레퍼럴 등록 받음
export const Referral = 4;
// 레퍼럴 금액 입금
export const ReferralReward = 5;
// 이자
export const Interest = 6;
// 채굴상품 가입
export const Subscribe = 7;
// 채굴상품 종료
export const SubscribeEnd = 8;
// 채굴 취소 요청
export const UnsubscribeReq = 9;
// 채굴 취소 완료
export const Unsubscribed = 10;
// 출금 취소(관리자)
export const WithdrawCanceled = 11;
// 친구한테 보냄
export const Transfer = 12;
// 친구한테 송금받음
export const Receive = 13;
// 송금요청 거절
export const TransferDenied = 14;
// 커뮤니티 보상
export const CommunitIncentive = 15;
// 관리자 입금
export const AdminDeposit = 16;
// 관리자 출금
export const AdminWithdraw = 17;

export const LockupUnlock = 18; // 락업 해제
export const ReferralUnlock = 19; // 추천인 락업 해제


// 전송(출금) 로그 상태
export const TransState_Processing = 0; // 처리중
export const TransState_Completed = 1; // 출금 완료
export const TransState_Refused = 3; // 승인 거절

// 
export const NamesData: LogNamesData = {
  // 입금
  '1': { name: '입금', color: '#e5346b' },
  // 출금요청
  '2': { name: '출금요청', color: '#478cff' },
  // 출금완료
  '3': { name: '출금', color: '#478cff' },
  // 레퍼럴 등록 받음
  '4': { name: '추천인 등록', color: '#e5346b' },
  // 레퍼럴 금액 입금
  '5': { name: '추천인 수익', color: '#009988' },
  // 이자
  '6': { name: '스테이킹 인센티브', color: '#009988' },
  // 채굴상품 가입
  '7': { name: '스테이킹', color: '#fd8934' },
  // 채굴상품 종료
  '8': { name: '스테이킹 종료', color: '#fd8934' },
  // 채굴 취소 요청
  '9': { name: '언스테이킹', color: '#002467' },
  // 채굴 취소 완료
  '10': { name: '언스테이킹 완료', color: '#002467' },
  // 출금 취소(관리자)
  '11': { name: '출금취소 (관리자)', color: '#478cff' },
  // 친구한테 보냄
  '12': { name: '내부출금', color: '#478cff' },
  // 친구한테 송금받음
  '13': { name: '내부입금', color: '#e5346b' },
  // 송금요청 거절
  '14': { name: '송금요청 거절', color: '#478cff' },
  // 커뮤니티 보상
  '15': { name: '커뮤니티 보상', color: '#009988' },
  // 관리자 입금
  '16': { name: '입금 (관리자)', color: '#e5346b' },
  // 관리자 출금
  '17': { name: '출금 (관리자)', color: '#478cff' },
  // 락업 해제
  '18': { name: '락업해제', color: '#e5346b' },
  // 추천인 락업 해제
  '19': { name: '추천인 락업해제', color: '#e5346b' },
  // ETH 상품 환불
  '20': { name: 'SPECIAL STAKING 환불', color: '#002467' },
  // EXE-GO -> EXE
  '21': { name: 'EXE-GO', color: '#478cff' },
  // ETH 상품 보상
  '22': { name: 'SPECIAL STAKING 보너스', color: '#009988' },
  // 바우처 구매
  '31': { name: '모바일 바우처 구매', color: '#478cff' },
  // 바우처 환불
  '32': { name: '모바일 바우처 환불', color: '#002467' },
  // 관리자 바우처 구매(입금)
  '33': { name: '관리자 바우처 구매', color: '#002467' },
  // 관리자 바우처 환불(출금)
  '34': { name: '관리자 바우처 환불', color: '#478cff' },
  // 락업 출금(전송)
  '41': { name: '락업자산 내부 출금', color: '#478cff' },
  // 락업 입금
  '42': { name: '락업자산 내부 입금', color: '#e5346b' },
  // 락업 해제
  '43': { name: '락업기간 해제', color: '#002467' },
  // 송금요청 거절
  '44': { name: '락업자산 출금 거절', color: '#478cff' },
  // 에어드럽 이벤트
  '51': { name: 'AIRDROPEVENT', color: '#e5346b' },
  // 출금
  '71': { name: '출금', color: '#478cff' },
}
