import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

export interface IUser {
    userId: string;
    tkUserId: string;
}

// export interface IUserInfo {
//     mbIdx: number;
//     id: string;
//     name: string;
//     phoneNo: string;
//     email: string;
//     recommendCode: string;
//     state: number;
// }

export const useStore = defineStore("Wallet", {
    state: () => ({
        user: useStorage("user", {} as IUser),
        userInfo: useStorage("userInfo", {} as any),
        symbol: useStorage("symbol", ''),
        pin: useStorage("pin", ''),
        sell: {
            box1: false,
            box2: false,
            box3: false,
        },
        terms: {
            box1: false,
            box2: false,
        }
    }),
    actions: {
        setUser(user: IUser) {
            this.user = user;
        },
        setUserInfo(userInfo: any) {
            this.userInfo = userInfo;
        },
        setSymbol(symbol: string) {
            this.symbol = symbol
        },
        setPin(pin: string){
            this.pin = pin
        },
        // updateUserInfo()
        clearUser() {
            this.user = {} as IUser;
            this.userInfo = {};
            this.symbol = '';
            this.pin = '';
        },
        setSell(idx:number, state: boolean){
            if(idx === 1){
                this.sell.box1 = state;
            } else if(idx === 2){
                this.sell.box2 = state;
            } else if(idx === 3){
                this.sell.box3 = state;
            }
        },
        setTerms(idx:number, state: boolean){
            if(idx === 1){
                this.sell.box1 = state;
            } else if(idx === 2){
                this.sell.box2 = state;
            }
        },
        clearSell(){
            this.sell.box1 = false;
            this.sell.box2 = false;
            this.sell.box3 = false;
        },
        clearTerms(){
            this.terms.box1 = false;
            this.terms.box2 = false;
        }
    },
});
